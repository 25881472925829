function Who() {
    return (
        <div id="who" className="whoContainer">
            <div className="textContainer">
                <div className="textbox">
                    "Somos especialistas en labores de limpieza e higienización cumpliendo los estandares más exigentes del sector alimentario"
                </div>
            </div>
        </div>
    );
}

export default Who;
