import { useEffect } from "react";
import { animationInit } from "../../utils/animations";

function What() {

    useEffect(() => {
        animationInit("what");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="what" className="whatContainer">
            <div className="section">
                <div className="extraSpace" />
                <div className="titleContainer">
                    <div className="title">¿Qué hacemos?</div>
                    <div className="subtitle">Somos una empresa de limpieza e higienización</div>
                </div>
                <div className="description">
                    <div className="sectionDescription">
                        <div className="sectionImage">
                            <a href="https://www.google.com/search?q=telide" target="_blank" rel="noreferrer">
                                <img src="./logoBody.png" alt="logo" />
                            </a>
                        </div>
                        <p><span className="dark-gold">TE.LI.DE.S.L</span> es una empresa que nace en <span className="dark-gold">1992</span> en el sector de servicios de higiene especializándose en el <span className="dark-gold">sector alimentario</span>. Posicionandose geográficamente en el área centro de España (<span className="dark-gold">Madrid - Castilla la Mancha</span>)</p>
                    </div>
                    <div className="sectionDescription">
                        <p>Somos especialistas en el <span className="dark-gold">desarrollo e implantacion de soluciones intregrales de higiene</span> que permiten a las empresas mejorar sus procesos y modelos productivos</p>
                        <div className="sectionImage">
                            <a href="https://www.google.es/maps/@40.170498,-3.6590629,10.25z" target="_blank" rel="noreferrer">
                                <img src="./mapa.jpg" alt="mapa" />
                            </a>
                        </div>
                    </div>
                    <div className="sectionDescription">
                        <div className="sectionImage">
                            <img src="./asesoramiento-head.jpg" alt="asesoramiento" />
                        </div>
                        <p>Proporcionamos a nuestros clientes un <span className="dark-gold">asesoramiento personalizado</span> que ayuda a desarrollar mejores estrategias para <span className="dark-gold">cumplir los objetivos</span> deseados</p>
                    </div>
                    <div className="sectionDescription">
                        <div className="sectionImage">
                            <img src="./agua.jpg" alt="agua" />
                        </div>
                        <p>Estamos especializados en <span className="dark-gold">minimizar el gasto de agua</span>, concienciados con la sostenibilidad del planeta. Que implica un <span className="dark-gold">ahorro en costes</span> en para los clientes</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default What;
