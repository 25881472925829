import { useState, useEffect, Fragment } from "react";
import { scrollToView, navigationSelectedByScroll } from "../../utils/scroll";

function Headers() {

    const langs = ['EN', 'ES'];
    const navItems = [
        { id: "who", title: "Quienes somos" },
        { id: "what", title: "Qué hacemos" },
        { id: "services", title: "Servicios" },
        { id: "process", title: "Proceso" },
        { id: "staff", title: "Personal" },
        { id: "objetive", title: "Objetivo" },
    ]

    const [showMobileMenu, setShowMobileMenu] = useState(false);

    useEffect(() => {
        navigationSelectedByScroll(navItems.map(navItem => navItem.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getIcon = () => showMobileMenu ? "/menu-hold.png" : "/menu.png";
    const renderMenu = () => navItems.map((navItem) => (
        <div key={navItem.id} id={`nav-${navItem.id}`} className="header-nav-item pointer">
            <span onClick={() => scrollToView(navItem.id)}>{navItem.title}</span>
        </div>
    ));
    const renderLangs = () => langs.map((lang, index) => {
        if (langs[index + 1]) {
            return (
                <Fragment key={lang}>
                    <span className="header-lang pointer">{lang}</span>
                    <span className="header-lang-separator">|</span>
                </Fragment>
            )
        } else {
            return (<span key={lang} className="header-lang selected pointer-block">{lang}</span>)
        }
    });

    return (
        <div className="header-bar">
            <div className={`header-content-left ${showMobileMenu ? 'show' : ''}`}>
                <div className="header-nav-group">
                    <div className="header-hamburger" onClick={() => setShowMobileMenu(!showMobileMenu)}><img className="menu-icon" src={getIcon()} alt="menu" /></div>
                    <img className="header-logo" src="/logo.png" alt="logo" />
                </div>
                <div className="header-nav">
                    {renderMenu()}
                </div>
            </div>
            <div className="header-content-right">
                {renderLangs()}
            </div>
        </div>
    );
}

export default Headers;
