const offset = 250;

const currentSelectedInView = (items) => {
    const scrollPosition = (window.scrollY || window.scrollTop || document.getElementsByTagName("html")[0].scrollTop) + offset;
    for (let i = 0; i < items.length; i++) {
        const idCurrentItem = items[i];
        const idNextItem = items[i + 1];
        const currentItem = document.getElementById(idCurrentItem);
        const nextItem = idNextItem ? document.getElementById(idNextItem) : null;

        if (nextItem && currentItem.offsetTop <= scrollPosition && scrollPosition < nextItem.offsetTop) {
            if (currentItem) document.getElementById(`nav-${idCurrentItem}`).classList.add("selected");
        } else if (!nextItem && currentItem.offsetTop <= scrollPosition) {
            if (currentItem) document.getElementById(`nav-${idCurrentItem}`).classList.add("selected");
        } else {
            if (currentItem) document.getElementById(`nav-${idCurrentItem}`).classList.remove("selected");
        }
    }
}

const navigationSelectedByScroll = (items) => {
    document.body.onscroll = () => currentSelectedInView(items);
    currentSelectedInView(items);
}

const scrollToView = (id) => {
    const element = document.getElementById(id);
    const position = element.offsetTop - offset;
    window.scrollTo({ top: position, behavior: 'smooth' });
}

export {
    scrollToView,
    navigationSelectedByScroll
}