import { useEffect } from "react";
import { animationInit } from "../../utils/animations";

function Services() {

    useEffect(() => {
        animationInit("services");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="services" className="containerServices">
            <div className="polygon">
                <div className="section">
                    <div className="titleContainer left">
                        <div className="title">Servicios</div>
                        <div className="subtitle">Nuestros principales servicios</div>
                    </div>
                    <div className="serviceContainer">
                        <h2 className="serviceIntroduction">Somos especialistas en labores de <span className="gold">limpieza e higienización</span>, cumpliendo con los requisitos mas exigentes del sector alimentario y de nuestros clientes, a través de <span className="gold">procedimientos que garantizan la higiene y seguridad</span> de los alimentos, instalaciones y de las personas. Nuestro <span className="gold">departamento de calidad</span> dispone de los medios mas avanzados para la <span className="gold">detección de las amenazas</span> principales del sector: análisis de presencia de Listeria, Salmonella, Campilobacter, detección de presencia de Biofilm, etc.</h2>
                        <div className="avaibleServices">
                            <ul className="serviceList">
                                <li><span className="icon">&gt;</span>Limpieza e higienización de salas productivas</li>
                                <li><span className="icon">&gt;</span>Sistemas de limpieza con espuma, vapor y/o hielo Seco</li>
                                <li><span className="icon">&gt;</span>Protocolos específicos por limpieza de máquina</li>
                                <li><span className="icon">&gt;</span>Limpieza e higienización de superficies</li>
                                <li><span className="icon">&gt;</span>Diferentes sistemas de controles microbiológicos</li>
                                <li><span className="icon">&gt;</span>Sistemas de detección de biofilms</li>
                                <li><span className="icon">&gt;</span>Higienización ambiental</li>
                                <li><span className="icon">&gt;</span>Control Contaminación Ambiental</li>
                            </ul>
                            <div className="serviceImage">
                                <div className="containerImage">
                                    <img src='./definicion.jpg' alt="services" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
