import { useState } from "react";

const dropdowns = [{
    id: 'experiencia',
    title: 'Experiencia',
    img: './experiencia.jpg',
    content: (
        <>
            <p>Integrada por expertos en las diferentes áreas de negocio, con una <span className="gold">amplia experiencia</span> en sectores fuertemente regulados, con sistemas de garantía de calidad exigentes.</p>
            <p>Contamos con <span className="gold">jefes de planta cualificados</span> en las regulaciones nacionales vigentes y las estrictas regulaciones internacionales demandadas por los clientes del sector, que incluyen tanto <span className="gold">normas internacionales (IFS; BRC)</span> como auditorías de segunda parte, realizadas por industria alimentaria de <span className="gold">ámbito internacional</span> (China, Japón, Mexico).</p>
        </>
    )
}, {
    id: 'matadero',
    title: 'Matadero',
    img: './matadero.jpg',
    content: (
        <p>El matadero, la sala de despiece, las líneas de envasado, los muelles de carga y descarga, oficinas, vestuarios… <span className="gold">Cada área requiere de unas necesidades de limpieza e higienización particulares</span>, y desde CES HIGIENE INDUSTRIAL - TELIDE luchamos para <span className="gold">disminuir la contaminación</span> por microorganismos alterantes, minimizar el riesgo de presencia de patógenos y <span className="gold">evitar las toxiinfecciones</span> en los consumidores potenciales.</p>
    )
}, {
    id: 'departamentos',
    title: 'Departamentos',
    img: './departamentos.jpg',
    content: (
        <>
            <p>Contamos con un <span className="gold">departamento de calidad</span> formado por veterinarios, biólogos y personal técnico, que <span className="gold">garantiza el cumplimiento normativo</span> de las operaciones de limpieza e higienización, procedimientos de muestreo y posterior análisis de verificación de actuaciones de limpieza e higienización.</p>
            <p>Nuestro <span className="gold">departamento de RRHH</span>, ha establecido convenios con las principales ETT del país con lo que conseguimos <span className="gold">optimizar el coste de personal</span>.</p>
            <p>Como <span className="gold">soporte adicional</span> disponemos de <span className="gold">personal técnico de mantenimiento</span>, que colabora en tareas de reparación de equipos de limpieza, así como de instalaciones.</p>
        </>
    )
}]

function Staff() {

    const [currentDropdown, setCurrentDropdown] = useState(dropdowns[0].id);
    const [currentImg, setCurrentImage] = useState(dropdowns[0].img);

    const openDropdown = (id) => {
        if (currentDropdown !== id) {
            setCurrentDropdown(id);
            const dropdown = dropdowns.find(dropdown => dropdown.id === id);
            document.querySelector('.containerImage img').classList.add("animate-image");
            setTimeout(() => {
                setCurrentImage(dropdown.img);
                setTimeout(() => document.querySelector('.containerImage img').classList.remove("animate-image"), 1000);
            }, 1000);
        }
    }

    const renderDropdowns = () => {
        return dropdowns.map((dropdown, index) => (
            <div key={dropdown.id} id={dropdown.id} className="accordion">
                <div className="dropdown" onClick={() => openDropdown(dropdown.id)}>
                    <h2 className="title">{dropdown.title}</h2>
                    <div className={`dropdownIcon ${currentDropdown === dropdown.id ? 'open' : ''}`}>&lt;</div>
                </div>
                <div className={`description ${currentDropdown === dropdown.id ? 'open' : ''}`}>
                    <div className="descriptionHeigth">
                        {dropdown.content}
                    </div>
                </div>
                {index + 1 !== dropdowns.length && <hr />}
            </div>
        ))
    }

    return (
        <div id="staff" className="containerStaff">
            <div className="polygon">
                <div className="section">
                    <div className="titleContainer left">
                        <div className="title">Personal</div>
                        <div className="subtitle">El mejor personal a tu servicio</div>
                    </div>
                    <div className="staffContent">
                        <div className="accordions">
                            {renderDropdowns()}
                        </div>
                        <div className="imageZone">
                            <div className="containerImage">
                                <div className="opacity">
                                    <img src={currentImg} alt={currentDropdown} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Staff;
