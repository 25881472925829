import Header from '../global/header';
import Footer from '../global/footer';
import Who from '../sections/who';
import What from '../sections/what';
import Services from '../sections/services';
import Process from '../sections/process';
import Staff from '../sections/staff';
import Objetive from '../sections/objetive';

function main() {
  return (
    <div>
      <Header />
      <Who />
      <What />
      <Services />
      <Process />
      <Staff />
      <Objetive />
      <Footer />
    </div>
  );
}

export default main;
