const cards = [{
    key: "rentar",
    title: "Rentabilidad",
    src: "/rentabilidad.jpg",
    desc: "Nos centramos en reducir los costes sin reducir la calidad"
}, {
    key: "sostener",
    title: "Sostenibilidad",
    src: "/sostenibilidad.jpg",
    desc: "Ahorrando agua y utilizando químicos respetuosos con el medio ambiente"
}, {
    key: "asesorar",
    title: "Asesorar",
    src: "/asesoramiento.jpg",
    desc: "Hacemos asesoramiento integral a lo largo de la implantación del proyecto"
}, {
    key: "facilitar",
    title: "Facilitar",
    src: "/facilitar.jpg",
    desc: "Estudiamos para facilar el proceso del producto"
}, {
    key: "investigar",
    title: "Investigar",
    src: "/investigar.jpg",
    desc: "Investigamos para estar a la vanguardia de los nuevos procesos tecnológicos"
}]

function Objetive() {

    const generateCards = () => cards.map(card => {
        return (
            <div key={card.key} className="card">
                <div className="cardContent">
                    <div className="front">
                        <img src={card.src} alt={card.title} />
                        <p>{card.title}</p>
                    </div>
                    <div className="back">
                        <p>{card.desc}</p>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div id="objetive" className="objetiveContainer">
            <div className="section">
                <div className="extraSpace" />
                <div className="titleContainer">
                    <div className="title">Objetivos</div>
                    <div className="subtitle">Nuestros lemas son..</div>
                </div>
                <div className="cardsContainer">
                    {generateCards()}
                </div>
                <div className="contact">
                    <p>Si esta interesado o necesita más información, contacte con nosotros al correo <a href="mailto:info@higieneindustrial.es" title="correo">info@higieneindustrial.es</a></p>
                </div>
            </div>
            <div className="extraSpace" />
        </div>
    );
}

export default Objetive;
