function Footer() {
    return (
        <div className="footer">
            <div class="carrouselZone" style={{ display: "none" }}>
                <div class="carrousel">
                    <ul class="carrouselTracks">
                        <li class="track">
                            <img alt="logo telide" src="./logos/telide.png" />
                        </li>
                    </ul>
                </div>
            </div>
            <hr />
            <div className="footer-basic">
                <div>
                    <img src="/logo.png" alt="logo" className="footer-logo" />
                </div>
                <div style={{ display: "none" }}>
                    <a href="https://www.aenor.com/certificacion/calidad/iso-9001" target="_blank" rel="noreferrer">
                        <img src="/9001.jpg" alt="9001" className="footer-iso" />
                    </a>
                    <a href="https://www.aenor.com/certificacion/alimentacion/seguridad-alimentaria" target="_blank" rel="noreferrer">
                        <img src="/22000.jpg" alt="22000" className="footer-iso" />
                    </a>
                </div>
                <div className="footer-copyright">
                    <div className="dark-gold">© 2024 TELIDE S.L.</div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
