import { useState, useEffect } from "react";

const phases = [{
    key: 1,
    title: <>Fase 1: <span className="dark-gold">Definición</span></>,
    description: 'Definición de la estructura humana necesaria para las labores y de la maquinaria que se va a utilizar en la limpieza',
    image: './definicion.jpg'
}, {
    key: 2,
    title: <>Fase 2: <span className="dark-gold">Prototipo</span></>,
    description: 'Diseño del prototipo del servicio que se va a utilizar para cumplir los objetivos establecidos',
    image: './prototipos.jpg'
}, {
    key: 3,
    title: <>Fase 3: <span className="dark-gold">Desarrollo</span></>,
    description: 'Desarrollo del servicio tal cual se ha establecido en el prototipo siguiendo los más altos estandares',
    image: './maquinaria.jpeg'
}, {
    key: 4,
    title: <>Fase 4: <span className="dark-gold">Implantación</span></>,
    description: 'Implantación del servicio y revisión de la consecución de los objetivos con el cliente',
    image: './implantacion.jpg'
}]

let timeout;
function Process() {

    const [currentPhase, setCurrentPhase] = useState(0);

    useEffect(() => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => nextPhase(), 5000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPhase]);

    const nextPhase = () => {
        let phaseToSet = currentPhase + 1;
        if (currentPhase + 1 === phases.length) phaseToSet = 0;
        setCurrentPhase(phaseToSet);
    }
    const previousPhase = () => {
        setCurrentPhase(currentPhase - 1);
    }

    const renderSlides = () => {
        return phases.map((phase, index) => (
            <div key={phase.key} className="slide" style={{ left: `${(index * 100)}%` }}>
                <div className="textSlide">
                    <p className="title">{phase.title}</p>
                    <p className="description">{phase.description}</p>
                </div>
                <div className="imageSlide">
                    <img src={phase.image} alt={phase.title} />
                </div>
            </div>
        ))
    }
    return (
        <div id="process" className="processContainer">
            <div className="section">
                <div className="extraSpace" />
                <div className="titleContainer">
                    <div className="title">Nuestro proceso</div>
                    <div className="subtitle">Garantizamos la calidad en nuestro proceso</div>
                </div>
                <div>
                    <p className="introduction">Todos nuestros servicios pasan por <span className="dark-gold">cuatro fases</span>, en las cuales se espera la <span className="dark-gold">aprobación del cliente</span> para poder comentar con la siguiente fase del proceso.</p>
                    <div className="containerSlides">
                        <div className="slidersZone">
                            <div className="slidersContainer" style={{ transform: `translateX(${currentPhase * (-100)}%)` }}>
                                {renderSlides()}
                            </div>
                        </div>
                        <div className="controlsZone">
                            <div className={`control iconBack ${currentPhase > 0 ? '' : 'hidden'}`} onClick={previousPhase}>&lt;</div>
                            <div className={`control iconNext ${currentPhase < phases.length - 1 ? '' : 'hidden'}`} onClick={nextPhase}>&gt;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Process;
