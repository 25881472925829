const observerWhat = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        const section = entry.target;

        if (entry.isIntersecting) {
            section.classList.add("move-animation");
            return; // if we added the class, exit the function
        }

        // We're not intersecting, so remove the class!
        section.classList.remove("move-animation");
    });
});

const observerService = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        const element = entry.target;
        if (entry.isIntersecting) {
            element.classList.add("move-animation");
            return; // if we added the class, exit the function
        }
    });
});

const animationInit = (type) => {
    if (type === "what") {
        const elements = document.querySelectorAll('.sectionDescription');
        elements.forEach(element => observerWhat.observe(element));
    } else if (type === "services") {
        observerService.observe(document.querySelector('.serviceList'));
        observerService.observe(document.querySelector('.serviceImage'));
    }
}

export {
    animationInit
}
